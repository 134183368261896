* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
  font-size: 18px;
}

/* navbar */
.navbar {
  background-color: aliceblue;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  min-height: 70px;
  margin-bottom: 20px;
  position: fixed; /* Set position to fixed */
  top: 0; /* Position at the top of the viewport */
  width: 100%; /* Occupy full width */
  z-index: 1000; /* Set a higher z-index */
}


/* logo  */
.logo {
  height: 100px;
}


/* ul  */
.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}

.navLink{
  font-size: 24px;
  font-weight: 500;
  color: #120f0b;
}

.selected {
  color: rgb(57, 39, 255);
}


/* media queries */

@media screen and (max-width: 780px){
  
  /* hamburger */
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .bar {
        background-color: #120f0b;
        width: 20px;
        height: 3px;
        display: block;
        margin: 5px;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

  /* adding active class to the bars  */
  .hamburger.active .bar:nth-child(2){
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1){
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3){
    transform: translateY(-8px) rotate(-45deg);
  }


  /* navMenu */

  .navMenu{
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 70px;
    left: -100%;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
    background-color: aliceblue;
    padding: 10px;
  }

  .navMenu.active{
    left: 0;
  }

  .navMenu li {
    margin: 16px 0;
  }
}
