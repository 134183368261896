@tailwind base;

@layer base {
  html {
    @apply text-neutral-800;
  }

  html.dark {
    @apply text-neutral-50;
    @apply bg-neutral-800;
  }
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}