.roles {
    display: flex;
    /* justify-content: space-around; */
    flex-direction: row;
    gap: 15px;
    width: 100%;
    margin-bottom: 25px;
}

.role {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.role h3 {
    font-size: 16px;
    margin-bottom: 0;
}

.role.selected {
    border-color: #3b71ca;
    background-color: #E8F0FE;
}

/* .borderedContainer {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    background-image: url('../../assets/images/bgOp.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
} */

.parentContainer {
    padding: 20px;
    text-align: center;
}